import * as React from "react";
import { connect } from "react-redux";
import Enumerable from "linq";
import useFileAttachement from "@bit/hlouzek.rhplus.file-attachement/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useApiComponent } from "@bit/hlouzek.rhplus.api";
import { FilePdfFilled } from "@ant-design/icons";
import service from "api/service";
import {
  scopeMeetingIdAtom,
  scopeMeetingHeadSelector,
} from "recoils/scopeMeetings";
import { useRecoilValue } from "recoil";
import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useData from "data/useData";

const shiftLetters = (str) => {
  if (!str) return "A";
  let newStr = "";
  let newCharCode = str.charCodeAt(0) + 1;
  newStr = String.fromCharCode(newCharCode);
  return newStr;
};

export default function HeaderRevision(props) {
  const { match } = props;
  const id = useRecoilValue(scopeMeetingIdAtom);
  const { callApi } = useApiComponent(null);
  const [loading, setLoading] = React.useState(false);
  const scopeMeeting = useRecoilValue(scopeMeetingHeadSelector);
  const [modal, contextHolder] = Modal.useModal();
  const { createNewRevisionAsync } = useData();

  const name = (scopeMeeting?.revision?.name);
  const newRevision = shiftLetters(name);

  const changeRevision = () => {};

  const ShowIcon = () => {
    if (!id) return <div />;

    if (!!loading) {
      return (
        <div>
          <CircularProgress color="white" style={{ width: 20, height: 20 }} />
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <a>
            <Button
              onClick={confirm}
              type="primary"
              // shape="circle"
              size="large"
            >
              <div style={{marginTop: 4}}>Create {newRevision}</div>
            </Button>
          </a>
        </div>
      );
    }
  };

  const confirm = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to create a revision ${newRevision}`,
      okText: "Create",
      cancelText: "Cancel",
      onOk: async (e) => {
        await createNewRevisionAsync({
          scopeMeetingId: scopeMeeting.head.id,
          sign: newRevision,
        });
      },
    });
  };

  return (
    <div>
      <ShowIcon />
      {contextHolder}
    </div>
  );
}
