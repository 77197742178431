import * as React from "react";
import {
  scopeMeetingsAtom,
  scopeMeetingDetailAtom,
  worksheetTabsSelector,
  scopeMeetingIdAtom,
  updateScopeMeetingitemPusherSelector
} from "recoils/scopeMeetings";
import { useRecoilState, useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import service from "api/service";
import { useApiComponent } from "@bit/hlouzek.rhplus.api";
import { usePusher } from "@bit/hlouzek.rhplus.pusher-recoil";

export const FetchData = async (api, data, proxy = null) => {
  const { callApi } = useApiComponent(null);
  const response = await callApi({ ...api, paramObject: data });
  return response;
};

export const api = {
  scopeMeetings: () => FetchData(service.scopeMeetings, {}),
  scopeMeetingHead: id => FetchData(service.scopeMeetingHead(id)),
  scopeMeetingDetail: id => FetchData(service.scopeMeetingDetail(id)),
  scopeMeetingItemDetail: id => FetchData(service.scopeMeetingItemDetail(id)),
  updateScopeMeetingItem: data => FetchData(service.updateScopeMeetingItem, data),
  removeScopeMeetingItem: data => FetchData(service.removeScopeMeetingItem, data),
  createScopeMeetingItem: data => FetchData(service.createScopeMeetingItem, data),
  createNewRevision: data => FetchData(service.createNewRevision, data),
  worksheets: () => FetchData(service.worksheets()),
  departments: () => FetchData(service.departments()),
  products: () => FetchData(service.products()),
  deleteFiles: data => FetchData(service.deleteFiles, data)
};
 
export default function useData() {
  const scopeMeetings = useRecoilValue(scopeMeetingsAtom);  
  const scopeMeetingDetail = useRecoilValue(scopeMeetingDetailAtom);
  const [, setScopeMeetingId] = useRecoilState(scopeMeetingIdAtom);
  const [isLoading, setIsLoading] = React.useState(false);
  const { trigger } = usePusher();
  const refreshScopeMeetingDetail = useRecoilRefresher_UNSTABLE(scopeMeetingIdAtom);


  const removeScopeMeetingItemAsync = async (scopeMeetingId, id) => {    
    setIsLoading(true);
    try {
      var deleted = await api.removeScopeMeetingItem({scopeMeetingId, id});
      setIsLoading(false);
      const {data} = deleted;      
      trigger("delete-item", data).then();
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
  }

  const createNewRevisionAsync = async ({scopeMeetingId, sign}) => {
    setIsLoading(true);
    try {
      var created = await api.createNewRevision({scopeMeetingId, sign});
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  }

  const updateScopeMeetingItemAsync = async input => {
    setIsLoading(true);
    const update = new FormData();
    update.append("Id", input.id);
    update.append("ProductCode", input.productCode);
    update.append("ProductDescription", input.productDescription);
    if (input.quantity) update.append("Quantity", input.quantity);
    if (input.description) update.append("Description", input.description);
    
    try {
      var updated = await api.updateScopeMeetingItem(update);
      setIsLoading(false);
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
    const {data} = updated;
    console.log("🚀 ~ file: useData.js:86 ~ updateScopeMeetingItemAsync ~ updated:", updated)
    trigger("update-item", data).then();
  };

  const createScopeMeetingItemAsync = async input => {
    setIsLoading(true);
    const create = new FormData();    
    if (input.productCode) create.append("ProductCode", input.productCode);
    if (input.productDescription) create.append("ProductDescription", input.productDescription);
    if (input.description) create.append("Description", input.description);
    if (input.quantity) create.append("Quantity", input.quantity);
    create.append("Folder", input.folder);
    create.append("ScopeMeetingId", input.scopeMeetingId);
    
    try {
      var created = await api.createScopeMeetingItem(create);
      setIsLoading(false);
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
    const {data} = created;
    trigger("create-item", data).then();
  };


  return {
    scopeMeetings,
    isLoading,
    scopeMeetingDetail,
    setScopeMeetingId  ,
    updateScopeMeetingItemAsync,
    createScopeMeetingItemAsync,
    removeScopeMeetingItemAsync,
    createNewRevisionAsync
  };
}
