import { selector, selectorFamily, atom, atomFamily } from "recoil";
import { api } from "data/useData";
import Enumerable from "linq";
import { get } from "lodash";

export const scopeMeetingDetailExpandedAtom = atom({
  key: 'scopeMeetingDetailExpandedAtom',
  default: false
})

export const scopeMeetingDetailFilledAtom = atom({
  key: 'scopeMeetingDetailFilledAtom',
  default: false
})

const scopeMeetingsSelector = selector({
  key: "scopeMeetingsSelector",
  get: async () => {
    const response = await api.scopeMeetings();
    return response.data.records;
  },
  dangerouslyAllowMutability: true,
});

export const scopeMeetingsAtom = atom({
  key: "scopeMeetings",
  default: scopeMeetingsSelector,
  dangerouslyAllowMutability: true,
});


export const scopeMeetingHeadSelector = selector({
  key: "scopeMeetingHeadSelector",
  get: async ({ get }) => {
    const scopeMeetingId = get(scopeMeetingIdAtom);
    if (!scopeMeetingId) return null;
    const response = await api.scopeMeetingHead(scopeMeetingId);
    return response.data;
  }
});

const scopeMeetingSelector = selector({
  key: "scopeMeetingSelector",
  get: async ({ get }) => {
    const scopeMeetingId = get(scopeMeetingIdAtom);
    if (!scopeMeetingId) return null;
    const response = await api.scopeMeetingDetail(scopeMeetingId);
    return response.data.records;
  },
  dangerouslyAllowMutability: true,
});

export const scopeMeetingIdAtom = atom({
  key: "scopeMeetingIdAtom",
  default: 0,
});

export const scopeMeetingDetailAtom = atom({
  key: "scopeMeetingDetailAtom",
  default: scopeMeetingSelector,
  dangerouslyAllowMutability: true,
});

export const scopeMeetingDetailTabSelectorFamily = selectorFamily({
  key: 'scopeMeetingDetailTabselectorFamily',
  get: id => ({get}) => {
    const items = get(scopeMeetingDetailAtom);
    return Enumerable.from(items)
      .where(w => w.worksheetId === id)
      .toArray();
  },
  dangerouslyAllowMutability: true,
})

export const worksheetTabsSelector = selector({
  key: "worksheetTabsSelector",
  get: ({ get }) => {
    const id = get(scopeMeetingIdAtom);
    const items = get(scopeMeetingDetailAtom);
    var tabs = Enumerable.from(items)
      .groupBy((g) => g.worksheet?.id)
      .select((s) => ({ 
        id: s.key(),
        name: s.first().worksheet?.name }))
      .toArray();
    if (tabs.filter(f => f.id == 0).length == 0) {
      tabs = [...tabs, { id: 0, name: 'Abnormals'}];
    }
    return tabs;
  },
});

// export const scopeMeetingItemIdSelector = selectorFamily({
//   key: "scopeMeetingSelector",
//   get: (id) => async ({ get }) => {
//     if (!id) return {};
//     const response = await api.scopeMeetingItemDetail(id);
//     return response.data;
//   },
//   dangerouslyAllowMutability: true,
// });

// export const scopeMeetingItemIdAtomFamily = atomFamily({
//   key: "scopeMeetingItemIdAtom",
//   default: scopeMeetingItemIdSelector,
//   dangerouslyAllowMutability: true,
// });

