import * as React from "react";
import { connect } from "react-redux";
import Enumerable from "linq";
import useFileAttachement from "@bit/hlouzek.rhplus.file-attachement/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useApiComponent } from "@bit/hlouzek.rhplus.api";
import {FilePdfFilled} from '@ant-design/icons';
import service from 'api/service';
import {scopeMeetingIdAtom} from 'recoils/scopeMeetings';
import {useRecoilValue} from 'recoil';
import {Button} from 'antd';

export default function HeaderPdfCreate() {
  const { downloadFileStream } = useFileAttachement();
  const { callApi } = useApiComponent(null);
  const [loading, setLoading] = React.useState(false);
  const id = useRecoilValue(scopeMeetingIdAtom);
  const {scopeMeetingReport} = service;

  const ShowIcon = () => {
    if (!!loading) {
      return (
        <div>
          <CircularProgress color="white" style={{width: 20, height: 20}} />
        </div>
      );
    } else {
      if (!id)
        return <div />
      return (
        <div className="text-center">

          <a href={scopeMeetingReport(id).url} download type="application/pdf" >
            <Button type="primary" shape="circle" size="large" icon={<FilePdfFilled  style={{marginTop: 10}}/>}></Button>
          </a>
        </div>
      );
    }
  };

  return (
    <div>
      <ShowIcon />
    </div>
  );
};
