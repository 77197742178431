
const scopeMeetings = {
  url: `/api/scopemeeting/scopemeetings`,
  methodType: "GET",
  version: "1.0",
  compression: true,
}

const scopeMeetingDetail = id => ({
  url: `/api/scopemeeting/scopemeetingdetail/${id}`,
  methodType: "GET",
  version: "1.0",
  compression: true,
})

const scopeMeetingHead = id => ({
  url: `/api/scopemeeting/scopemeetinghead/${id}`,
  methodType: "GET",
  version: "1.0",
  compression: true,
})

const scopeMeetingItemDetail = id => ({
  url: `/api/scopemeeting/scopemeetingitemdetail/${id}`,
  methodType: "GET",
  version: "1.0",
  compression: true,
})

const uploadProductPhoto = {
  url: '/api/filestorage/uploadProductPhoto',
  methodType: 'PUT',
  version: "1.0"
}

const updateScopeMeetingItem = {
  url: '/api/scopemeeting/updatescopemeetingitem',
  methodType: 'PUT',
  version: "1.0"
}

const removeScopeMeetingItem = {
  url: '/api/scopemeeting/removescopemeetingitem',
  methodType: 'POST',
  version: "1.0"
}

const createScopeMeetingItem = {
  url: '/api/scopemeeting/createscopemeetingitem',
  methodType: 'PUT',
  version: "1.0"
}

const scopeMeetingReport = scopeMeetingId => {
  return {
  url: `/api/report/scopemeeting/${scopeMeetingId}`,
  methodType: 'GET',
  version: "1.0"
}};

const worksheets = () => {
  return {
  url: `/api/lookup/worksheets`,
  methodType: 'GET',
  version: "1.0"
}};

const departments = () => {
  return {
  url: `/api/lookup/departments`,
  methodType: 'GET',
  version: "1.0"
}};

const products = () => {
  return {
  url: `/api/lookup/products`,
  methodType: 'GET',
  version: "1.0"
}};

const deleteFiles = {
  url: '/api/scopemeeting/deletefiles',
  methodType: 'POST',
  version: "1.0"
}

const createNewRevision = {
  url: '/api/scopemeeting/CreateNewRevision',
  methodType: 'POST',
  version: "1.0"
}

export default {
  scopeMeetings,
  scopeMeetingDetail,
  scopeMeetingItemDetail,
  uploadProductPhoto,
  updateScopeMeetingItem,
  createScopeMeetingItem,
  removeScopeMeetingItem,
  scopeMeetingReport,
  worksheets,
  scopeMeetingHead,
  deleteFiles,
  departments,
  products,
  createNewRevision
}